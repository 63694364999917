import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "content" ]

  open(event) {
    console.log("open");
    event.preventDefault();
    let tune = JSON.parse(event.currentTarget.dataset.tune);
    this.contentTarget.innerText = tune.overall_rating; // Replace 'name' with the actual attribute you want to display
    this.modalTarget.classList.remove('hidden');
  }

  close() {
    this.modalTarget.classList.add('hidden');
  }
}