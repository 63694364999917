import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  filter() {
    // The filter function that will be called on 'change' event
    console.log("Rating select has changed to: ", this.element.min_rating.value);
    // Make sure you have a correct way to reference your path here.
    const url = new URL(this.element.action);
    url.searchParams.set('min_rating', this.element.min_rating.value);
    url.searchParams.set('min_upwind_speed', this.element.min_upwind_speed.value);
    url.searchParams.set('min_upwind_angle', this.element.min_upwind_angle.value);
    url.searchParams.set('min_downwind_speed', this.element.min_downwind_speed.value);
    url.searchParams.set('min_downwind_angle', this.element.min_downwind_angle.value);
    url.searchParams.set('min_groove', this.element.min_groove.value);
    url.searchParams.set('min_sea_state', this.element.min_sea_state.value);
    url.searchParams.set('min_wind', this.element.min_wind.value);
    url.searchParams.set('max_wind', this.element.max_wind.value);

    // Perform a GET request with Turbo
    // Turbo.visit(url);

    fetch(url, {
      method: this.element.method,
      headers: { 'Accept': 'text/vnd.turbo-stream.html' },
      credentials: 'same-origin'
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }
}
