import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "field"];

  updateDependentFields(event) {
    const originalSelect2Event = event.detail.event;
    const selectedValue = originalSelect2Event.target.value;
  
    if (selectedValue === "regatta") {
      console.log("show entries and finish");
      if (this.fieldTarget) {
        this.fieldTarget.classList.remove("hidden");
      }
    } else if (selectedValue === "training_event") {
      console.log("hide entries and finish");
      if (this.fieldTarget) {
        this.fieldTarget.classList.add("hidden");
      }
    }
  }
}
