import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat"
export default class extends Controller {
  connect() {
    console.log('chat controller is loaded up and ready')
    this.element[this.identifier] = this
    this.scrollToBottom()
  }

  scrollToBottom() {
    let height = this.element.scrollHeight
    this.element.scrollTo(0, height)
  }
}