import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "bladeField", "foilField", "lineField", "sailField", "sparField"];

  updateInventoryFields(event) {
    const originalSelect2Event = event.detail.event;
    const selectedValue = originalSelect2Event.target.value;

    console.log(selectedValue);
    
    this.hideAllInventoryFields();
    
    switch (selectedValue) {
      case "blade":
        this.bladeFieldTarget.classList.remove("hidden");
        break;
      case "foil":
        this.foilFieldTarget.classList.remove("hidden");
        break;
      case "sail":
        this.sailFieldTarget.classList.remove("hidden");
        break;
      case "spar":
        this.sparFieldTarget.classList.remove("hidden");
        break;
      case "line":
        this.lineFieldTarget.classList.remove("hidden");
        break;
    }
  }

  hideAllInventoryFields() {
    this.bladeFieldTarget.classList.add("hidden");
    this.foilFieldTarget.classList.add("hidden");
    this.lineFieldTarget.classList.add("hidden");
    this.sailFieldTarget.classList.add("hidden");
    this.sparFieldTarget.classList.add("hidden");
  }
}
