import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "field", "fieldOther", "cityStateClub"];

  updateDependentFields(event) {
    const originalSelect2Event = event.detail.event;
    const selectedValue = originalSelect2Event.target.value;
  
    if (selectedValue === "pre_regatta_practice") {
      console.log("show regatta ID");
      if (this.fieldTarget) {
        this.fieldTarget.classList.remove("hidden");
      }
    } else if (selectedValue === "race") {
      console.log("show regatta ID and other fields");
      if (this.fieldTarget) {
        this.fieldTarget.classList.remove("hidden");
      }
      if (this.fieldOtherTarget) {
        this.fieldOtherTarget.classList.remove("hidden");
      } // selectedValue === "race_day"
    } else if (selectedValue === "race_day") {
      console.log("race day");
      if (this.cityStateClubTarget) {
        this.cityStateClubTarget.classList.add("hidden");
      }
      if (this.fieldTarget) {
        this.fieldTarget.classList.remove("hidden");
      }
    } else {
      console.log("hide regatta ID");
      if (this.fieldTarget) {
        this.fieldTarget.classList.add("hidden");
        this.fieldOtherTarget.classList.add("hidden");
      }
    }
  }

  updateCityStateClubFields(event) {
    console.log("updateCityStateClubFields");
    const originalSelect2Event = event.detail.event;
    const selectedValue = originalSelect2Event.target.value;
  
    if (selectedValue !== '') {
      this.cityStateClubTarget.classList.add("hidden");
    } else {
      this.cityStateClubTarget.classList.remove("hidden");
    }
  }
}
