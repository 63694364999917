import { Controller } from "stimulus"

export default class extends Controller {
connect() {
    console.log("Test controller connected");
}

  static targets = ["content"]

  toggle() {
    console.log("Toggling visibility");
    this.contentTarget.classList.toggle("hidden");
  }
}