import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content", "button"]

  connect() {
    console.log("connected to tabs")
    const url = new URL(window.location);
    const selectedTab = url.searchParams.get('tab');
  
    if (selectedTab) {
      const selectedIndex = this.buttonTargets.findIndex(button => button.dataset.tabsButton === selectedTab);
      if (selectedIndex >= 0) {
        this.showTab(selectedIndex);
        this.buttonTargets[selectedIndex].classList.add("button-selected");
      } else {
        this.showTab(0); // Show the first tab if the selected tab is not found
        this.buttonTargets[0].classList.add("button-selected");
      }
    } else {
      this.showTab(0); // Show the first tab by default
      this.buttonTargets[0].classList.add("button-selected");
    }
  }

  switchTab(event) {
    const index = this.buttonTargets.indexOf(event.currentTarget)
    this.showTab(index)
    this.buttonTargets.forEach((button) => {
      if (button.dataset.tabsButton === event.target.dataset.tabsButton) {
        button.classList.add("button-selected")
      } else {
        button.classList.remove("button-selected")
      }
    })
    // Update the URL with the selected tab
    const url = new URL(window.location);
    url.searchParams.set('tab', event.target.dataset.tabsButton);
    window.history.pushState({}, '', url);
  }
  
  showTab(index) {
    this.buttonTargets.forEach((button, i) => {
      button.classList.toggle('active', i === index)
    })
    this.contentTargets.forEach((content, i) => {
      content.style.display = i === index ? 'block' : 'none'
    })
  }
}